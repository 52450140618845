import './HeroMobile.css'
import Navbar from '../Navbar'
import O1 from "../../Assests/gif1.gif";
import O2 from "../../Assests/gif2.gif";
import O3 from "../../Assests/gif3.gif";
import moneyback from '../../Assests/monryback.png'

const HeroMobile = ({text,setShowLogin,setShowSign}) => {

const isDs = text.includes("Data");
  return (
    <div className="hero-cont">
    <Navbar setShowLogin={setShowLogin} setShowSign={setShowSign}/>
    <div className='test-hero-cont'>
    <div className='test-hero-iit'>An IIT Delhi Alumni Initiative</div>
    <div className='test-hero-head'>
    {
      isDs ?<div className='test-hero-head-top'>Master <u><b>{text}</b></u>, <br/>for free with  <b>100% Scholarship</b></div>
      : <div className='test-hero-head-top'>Learn <u><b>{text}</b></u>, effectively for <b>Free</b></div>

    }
      <div className='test-hero-head-btm'>Here is how : 
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M4.77985 13.197L12.0008 14L12.0008 20C12.0008 20.5304 12.2116 21.0391 12.5866 21.4142C12.9617 21.7893 13.4704 22 14.0008 22C14.5313 22 15.04 21.7893 15.4151 21.4142C15.7901 21.0391 16.0008 20.5304 16.0008 20V8L19.0318 9.212C19.2849 9.31318 19.556 9.36157 19.8284 9.3542C20.1009 9.34683 20.3689 9.28386 20.6162 9.16914C20.8634 9.05442 21.0846 8.89039 21.2661 8.6871C21.4476 8.48382 21.5857 8.24558 21.6718 7.987L21.7848 7.647C21.8483 7.45619 21.8528 7.25069 21.7976 7.05732C21.7425 6.86394 21.6304 6.69167 21.4758 6.563L16.2788 2.231C16.0998 2.082 15.8728 2 15.6388 2L5.00085 2C4.47041 2 3.96171 2.21071 3.58663 2.58579C3.21156 2.96086 3.00085 3.46957 3.00085 4L3.00085 11.21C3.00103 11.702 3.18259 12.1767 3.5108 12.5433C3.839 12.9099 4.29082 13.1426 4.77985 13.197Z" fill="#FFD485"/></svg>
      </div>
    </div>
    <div className='test-ref-cont'>
    <div className="ref-new-backoffer-left test-ref-left">
          <div className="ref-new-bol-card bol-card1">
          <img src={moneyback} alt='moneyback' className='moneyback-test'/>
            <div className="ref-new-bol-card-left">
              <img src={O1} alt="Enroll" />
            </div>
            <div className="ref-new-bol-card-middle test-ref-middle">
              <div className="test-ref-new-bol-card-heading"><span>Enroll</span> By Paying  <br/><span>₹699</span> </div>
            </div>
            <div className="ref-new-bol-card-right">1</div>
          </div>
          <div className="ref-new-bol-card bol-card2">
            <div className="ref-new-bol-card-left">
              <img src={O2} alt="Course" />
            </div>
            <div className="ref-new-bol-card-middle test-ref-middle">
              <div className="test-ref-new-bol-card-heading">
              <span>Complete Course</span> and Submit Assignments
              </div>
            </div>
            <div className="ref-new-bol-card-right">2</div>
          </div>
          <div className="ref-new-bol-card bol-card3">
            <div className="ref-new-bol-card-left">
              <img src={O3} alt="Payment" />
            </div>
            <div className="ref-new-bol-card-middle test-ref-middle">
              <div className="test-ref-new-bol-card-heading">
              Get <span>100% Money Back</span> into Your bank
              </div>
            </div>
            <div className="ref-new-bol-card-right">3</div>
          </div>
        </div>
    </div>
    </div>
    </div>
  )
}

export default HeroMobile